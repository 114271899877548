import React from 'react';
import LoadingBar from 'react-redux-loading-bar';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';

import PrivateRoute from './private';

import AppLocale from '../lang';

// Landing Pages
import Landing from 'pages/landing';

// Auth Pages
import Login from 'pages/login';
import Register from 'pages/signup';
import ForgotPassword from 'pages/forget-password';
import ResetPassword from 'pages/reset-password';

// App Pages
import Dashboard from 'pages/app/dashboard';
import ReducePayment from 'pages/app/reduce-payment';
import PayBills from 'pages/app/pay-bills';
import WalletMenu from 'pages/app/wallet';
import WalletNormal from 'pages/app/wallet/normal';
import WalletReward from 'pages/app/wallet/reward';
import ShopMenu from 'pages/app/shop';
import ShopLocalAirtime from 'pages/app/shop/airtime/local';
import ShopData from 'pages/app/shop/data';
import ShopCableTV from 'pages/app/shop/cable-tv';
import ShopPower from 'pages/app/shop/power';
import ShopMovies from 'pages/app/shop/movies';
import ShopBroadband from 'pages/app/shop/broadband';
import ShopAirline from 'pages/app/shop/airline';
import ShopEducation from 'pages/app/shop/education';
import ShopTollbooth from 'pages/app/shop/tollbooth';
import ShopTraining from 'pages/app/shop/training';
import ShopEvents from 'pages/app/shop/events';
import ShopBetting from 'pages/app/shop/betting';
import ShopInternationalAirtime from 'pages/app/shop/airtime/international';
import Advertise from 'pages/app/advertise';
import AdvertiseCampaigns from 'pages/app/advertise/campaign';
import AdvertiseCreateCampaign from 'pages/app/advertise/campaign/create';
import AdvertiseCampaignStatistics from 'pages/app/advertise/campaign/statistics';
import AdvertiseViewCampaign from 'pages/app/advertise/campaign/view';
import AdvertiseContacts from 'pages/app/advertise/contacts';
import AdvertiseCredits from 'pages/app/advertise/credits';
import Invite from 'pages/app/invite';
import Profile from 'pages/settings/profile';
import Language from 'pages/settings/lang';
import Pin from 'pages/settings/pin';
import Password from 'pages/settings/password';
import FAQ from 'pages/settings/faq';
import HelpCenter from 'pages/settings/help-center';
import knowledgeBase  from '../pages/settings/knowledge-base';
import PrivacyPolicy from 'pages/settings/privacy-policy';
import TermsConditions from 'pages/settings/terms-&-conditions';
import NotFound from 'pages/not-found';
import LangContextProvider from '../context/AddLangContext';
import EmailVerification from 'pages/email-verification';
import LandingPage from 'pages/LandingPage';

const Router = () => {
  const { lang } = useSelector((state) => state.authReducers);
  const currentAppLocale = AppLocale[lang];
  return (
    <div className="bg-white">
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <BrowserRouter>
          <LoadingBar style={{ position: 'fixed', zIndex: 5, backgroundColor: '#1976D2' }} />
          <LangContextProvider>
            <Switch>


              {/* <Redirect exact from="/" to="/home" /> */}
              <Route exact path="/" component={Landing.Home} />
              {/* <Route exact path="/home" component={LandingPage} /> */}
              <Route exact path="/new-landing-page-12345" component={LandingPage} />
              <Route exact path="/new-landing-page-12345/:page" component={LandingPage} />
              <Route exact path="/about-us" component={Landing.AboutUs} />
              <Route exact path="/value-proposition" component={Landing.ValueProposition} />
              <Route exact path="/pricing" component={Landing.Pricing} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={Register} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/reset-password/:token" component={ResetPassword} />
              <Route exact path="/email-verification/:token" component={EmailVerification} />
              <Route exact path="/faqs" component={FAQ} />
              <Route exact path="/knowledge-base" component={knowledgeBase} />
              <Route exact path="/help-center" component={HelpCenter} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/terms-&-conditions" component={TermsConditions} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/reduce-your-payment" component={ReducePayment} />
              <PrivateRoute exact path="/pay-bills" component={PayBills} />
              <PrivateRoute exact path="/settings/profile" component={Profile} />
              <PrivateRoute exact path="/settings/language" component={Language} />
              <PrivateRoute exact path="/settings/pin" component={Pin} />
              <PrivateRoute exact path="/settings/password" component={Password} />
              <PrivateRoute exact path="/wallet" component={WalletMenu} />
              <PrivateRoute exact path="/wallet/normal" component={WalletNormal} />
              <PrivateRoute exact path="/wallet/reward" component={WalletReward} />

              <PrivateRoute exact path="/shop" component={ShopMenu} />
              <PrivateRoute exact path="/shop/airtime/local" component={ShopLocalAirtime} />
              <PrivateRoute exact path="/shop/data_bundle" component={ShopData} />
              <PrivateRoute exact path="/shop/cable_tv" component={ShopCableTV} />
              <PrivateRoute exact path="/shop/power" component={ShopPower} />
              <Redirect from="/shop/iroko" to="/shop/movies" />
              <PrivateRoute exact path="/shop/movies" component={ShopMovies} />
              <PrivateRoute exact path="/shop/internet" component={ShopBroadband} />
              <PrivateRoute exact path="/shop/airlines" component={ShopAirline} />
              <PrivateRoute exact path="/shop/training" component={ShopTraining} />
              <PrivateRoute exact path="/shop/education" component={ShopEducation} />
              <PrivateRoute exact path="/shop/toll_booth" component={ShopTollbooth} />
              <PrivateRoute exact path="/shop/events" component={ShopEvents} />
              <PrivateRoute exact path="/shop/betting" component={ShopBetting} />
              <PrivateRoute
                exact
                path="/shop/airtime/international"
                component={ShopInternationalAirtime}
              />

              <PrivateRoute exact path="/advertise" component={Advertise} />
              <PrivateRoute exact path="/advertise/campaigns" component={AdvertiseCampaigns} />
              <PrivateRoute
                exact
                path="/advertise/campaigns/create"
                component={AdvertiseCreateCampaign}
              />
              <PrivateRoute
                exact
                path="/advertise/campaigns/:key/statistics"
                component={AdvertiseCampaignStatistics}
              />
              <PrivateRoute
                exact
                path="/advertise/campaigns/:key/view"
                component={AdvertiseViewCampaign}
              />
              <PrivateRoute exact path="/advertise/contacts" component={AdvertiseContacts} />
              <PrivateRoute exact path="/advertise/credits" component={AdvertiseCredits} />
              <PrivateRoute exact path="/invite" component={Invite} />
              <Route exact path="/not-found" component={NotFound} />
              <Redirect from="*" to="/not-found" />
            </Switch>
          </LangContextProvider>
        </BrowserRouter>
      </IntlProvider>
    </div>
  );
};

export default Router;
